import * as React from "react"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import Breadcrumbs from "../../../components/breadcrumbs"
import Hero from "../../../components/hero"
import ImageTeaser from "../../../components/image-teaser"
import PageHeader from "../../../components/page-header"
import SectionHeader from "../../../components/section-header"
import Footer from "../../../components/footer"
import VideoOverlay from "../../../components/video-overlay"

const imgAR = require("../../../images/roster-om-demokrati/alexander_rothman.jpg")
const imgFC = require("../../../images/roster-om-demokrati/francesco_kovacs.jpg")
const imgDR = require("../../../images/roster-om-demokrati/dina_rajs.jpg")

import "../../../scss/site.scss"
//import StickyCta from "../../../components/sticky-cta"

const VoicesOnDemocracy = props => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [caption, setCaption] = useState(null)
  const [url, setUrl] = useState(null)
  const [signUrl, setSignUrl] = useState(null)

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
    return () => {
      document.body.style.overflowY = ""
    }
  }, [showOverlay])

  const handleShowOverlay = (caption, url, signUrl) => {
    if (caption) setCaption(caption)
    if (url) setUrl(url)
    if (signUrl) setSignUrl(signUrl)
    setShowOverlay(!showOverlay)
  }

  const videoOverlayId = "roster-om-demokrati-video-overlay"

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Röster Om Demokrati | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/roster-om-demokrati"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/roster-om-demokrati"
        ></meta>
        <meta property="og:title" content="Röster Om Demokrati"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader />
        <div
          role="main"
          id="main"
          className="o-main c-main-background c-main-background--om-demokrati"
        >
          <Breadcrumbs
            modifier="indent"
            crumbs={props.pageContext.breadcrumb}
          />
          <Hero
            buttonLink="handledning"
            buttonText="Till läraren"
            modifier="has-breadcrumbs"
            title="Röster om demokrati"
          >
            <p>
              Alexander Rothman, Franscesco Kovacs och Dina Rajs har själva
              upplevt diktatur. Här pratar de om demokratins värden. Varför
              behövs demokrati? Vad betyder demokrati egentligen och hur kan vi
              gemensamt förbättra den? Se filmerna och diskutera tillsammans.
            </p>
            <p>
              Intervjuerna innehåller svåra ord som kan behöva förklaras för
              eleverna. Se vidare i <em>Till läraren</em>.
            </p>
          </Hero>
          <section
            className="c-content-section"
            aria-labelledby="lyssnaPaBerattelserna"
          >
            <SectionHeader
              id="lyssnaPaBerattelserna"
              text="Lyssna på berättelserna"
            />
            <div className="container c-content-section__container">
              <div className="c-content-section__content c-content-section__content--fluid">
                <div className="c-content-section__row row">
                  <div className="col-xs-12 col-md-4">
                    <ImageTeaser
                      video="yes"
                      modifier="small"
                      img={imgAR}
                      imgAlt="Porträtt på Alexander Rothman"
                      title="Alexander Rothman"
                      buttonText="Se filmen"
                      datetime="Ca 1:30 min"
                      onClick={() =>
                        handleShowOverlay(
                          "Alexander Rothman föddes 1930 i Ungern. Överlevde nazisternas förföljelser och förintelselägret Auschwitz. Kom till Sverige 1947.",
                          "https://player.vimeo.com/video/511106856?api=1&player_id=vimeoplayer&title=0&byline=0&portrait=0&color=0BA126",
                          "https://vimeo.com/527307558"
                        )
                      }
                      ariaControls={videoOverlayId}
                    >
                      <p>
                        Född 1930 Ungern. Överlevde nazisternas förföljelser och
                        förintelselägret Auschwitz. Kom till Sverige 1947.
                      </p>
                      <p>
                        <em>
                          – Demokrati är ingen garanti för att allt är bra.
                        </em>
                      </p>
                    </ImageTeaser>
                  </div>
                  <div className="col-xs-12 col-md-4">
                    <ImageTeaser
                      video="yes"
                      modifier="small"
                      img={imgFC}
                      imgAlt="Porträtt på Francesco Kovacs"
                      title="Francesco Kovacs"
                      buttonText="Se filmen"
                      datetime="Ca 1:30 min"
                      onClick={() =>
                        handleShowOverlay(
                          "Francesco Kovacs föddes 1929 i Ungern. Hölls fängslad av den sovjetiska diktaturen. Kom till Sverige 1954.",
                          "https://player.vimeo.com/video/511106927?api=1&player_id=vimeoplayer&title=0&byline=0&portrait=0&color=0BA126",
                          "https://vimeo.com/527308378"
                        )
                      }
                      ariaControls={videoOverlayId}
                    >
                      <p>
                        Född 1929 i Ungern. Hölls fängslad av den sovjetiska
                        diktaturen. Kom till Sverige 1954.
                      </p>
                      <p>
                        <em>
                          – Gör du bara så här lite så har du gjort någonting.
                        </em>
                      </p>
                    </ImageTeaser>
                  </div>
                  <div className="col-xs-12 col-md-4">
                    <ImageTeaser
                      video="yes"
                      modifier="small"
                      img={imgDR}
                      imgAlt="Porträtt på Dina Rajs"
                      title="Dina Rajs"
                      buttonText="Se filmen"
                      datetime="Ca 2 min"
                      onClick={() =>
                        handleShowOverlay(
                          "Dina Rajs föddes 1938 i Jugoslavien. Var barn under nazisternas förföljelser och levde under den kommunistiska diktaturen. Kom till Sverige 1968.",
                          "https://player.vimeo.com/video/511106902?api=1&player_id=vimeoplayer&title=0&byline=0&portrait=0&color=0BA126",
                          "https://vimeo.com/527307879"
                        )
                      }
                      ariaControls={videoOverlayId}
                    >
                      <p>
                        Född 1938 i Jugoslavien. Var barn under nazisternas
                        förföljelser och levde under den kommunistiska
                        diktaturen. Kom till Sverige 1968.
                      </p>
                      <p>
                        <em>– Det kallar jag Demokrati.</em>
                      </p>
                    </ImageTeaser>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        <VideoOverlay
          elementId={videoOverlayId}
          show={showOverlay}
          caption={caption}
          url={url}
          signUrl={signUrl}
          toggleShow={() => setShowOverlay(!showOverlay)}
        />
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default VoicesOnDemocracy
